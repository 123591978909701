<template>
  <UiPopup
    :model-value="modelValue"
    title="Try again later!"
    description="Support team is currently busy."
    primary-button-text="Thank you"
    size="small"
    @update:model-value="emits('update:modelValue', false)"
    @confirm="emits('update:modelValue', false)"
  >
  </UiPopup>
</template>

<script setup lang="ts">
const emits = defineEmits(['update:modelValue', 'input'])

type Props = {
  modelValue: boolean
}

withDefaults(defineProps<Props>(), {
  modelValue: false,
})
</script>
